<template>
  <div
    class="clock-section flex justify-evenly fade-left-element"
    v-bind:class="{ 'fade-left-element animate__animated animate__fadeInLeft ': isVisible }"
    ref="elementToObserve"
  >
    <div>
      <clock-component :timeZone="'America/Los_Angeles'"></clock-component>
      <p class="text-center c-name font-black has-font-circular-bold">USA</p>
      <p
        class="text-center address w-3/5 mx-auto has-font-circular-book"
      >225 Wilmington-West Chester Pike, Chadds Ford, Pennsylvania 19317, United States</p>
      <!-- <p class="text-center address w-4/5 mx-auto has-font-circular-book">Chadds Ford, Pennsylvania 19317, United States</p> -->
    </div>
    <div>
      <clock-component :timeZone="'Asia/Dubai'"></clock-component>
      <p class="text-center c-name font-black has-font-circular-bold">DUBAI</p>
      <p class="text-center address w-3/5 mx-auto has-font-circular-book">#806, 8th Floor, Liberty House, Dubai International Financial Centre (DIFC)</p>
    </div>
    <div>
      <clock-component :timeZone="'Asia/Kolkata'"></clock-component>
      <p class="text-center c-name font-black has-font-circular-bold">INDIA</p>
      <p
        class="text-center address w-3/5 mx-auto has-font-circular-book"
      >MIG 10, Sector 2, Shankar Nagar, Raipur, Chhattisgarh 492001, India</p>
      <!-- <p class="text-center address w-4/5 mx-auto has-font-circular-book">Raipur, Chhattisgarh 492001, India</p> -->
    </div>
  </div>
</template>

<script>
import ClockComponent from "../clock";
export default {
  components: {
    ClockComponent
  },
  data() {
    return {
      isVisible: false
    };
  },
  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.5 // Adjust this threshold value as needed
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      });
    }, options);

    observer.observe(this.$refs.elementToObserve);
  }
};
</script>

<style lang="scss" scoped>
.clock-section {
  @media (max-width: 500px) {
    flex-direction: column;

    .c-name {
      font-size: 12px;
      margin-bottom: 0.5em;
    }
    .address {
      font-size: 8px !important;
    }
  }
  .c-name {
    font-size: 14px;
  }
  .address {
    margin-bottom: 0 !important;
    font-size: 14px;
  }
}
</style>